#essay {
    #header {
        h1 {
            font-weight: 600;
            font-family: var(--title-font);
            font-size: 2em;
            margin-bottom: 5px;
        }

        #date {
            font-style: italic;
            margin: 0;
        }

        #quote {
            font-style: italic;
        }
    }

    #quote{
        text-align: center;

        @include for_breakpoint(desktop tablet){
                padding-left: 20%;
                padding-right: 20%;
                margin: 5% 0% 5% 0%;
        }
    }

    em, strong{
        color: var(--secondary-text-color);
    }
  
    .footnote, .reversefootnote{
        color: var(--footnote-link-color);
    }

    .footnotes{
        border-top: solid 1px;
        margin-top: 15px;
    }
}