social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 50px;
    align-items: center;

    img {
        height: 25px;
        width: 25px;
        @media (prefers-color-scheme: dark) {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(53deg) brightness(98%) contrast(113%);
        }
    }
}