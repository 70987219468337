html {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--main-background-color);
}

body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--main-text-color);
    margin: 0;
    font-family: var(--body-font);
}

a{
    color: var(--link-color);
}

.underline {
    text-decoration: underline;
}

article {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}