@font-face{
    font-family: 'Hind Madurai';
    src: url(../resources/fonts/Hind_Madurai/HindMadurai-Regular.ttf);
}

@font-face{
    font-family: 'Hind Madurai';
    src: url(../resources/fonts/Hind_Madurai/HindMadurai-Bold.ttf);
    font-weight: bold;
}

@font-face{
    font-family: 'Roboto';
    src: url(../resources/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face{
    font-family: 'Roboto';
    src: url(../resources/fonts/Roboto/Roboto-Bold.ttf);
    font-weight: bold;
}

@font-face{
    font-family: 'Martel';
    src: url(../resources/fonts/Martel/Martel-Regular.ttf);
}