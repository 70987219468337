@use "sass:list";
@use "sass:string";

$size__site_content_width: 1024px;

/* Media Queries */
$media_queries : (
    'mobile'    : "only screen and (max-width: 667px)",
    'tablet'    : "only screen and (min-width: 668px) and (max-width: #{$size__site_content_width})",
    'desktop'   : "only screen and (min-width: #{$size__site_content_width + 1})",
    'retina2'   : "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
    'retina3'   : "only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)",
    'landscape' : "screen and (orientation:landscape) ",    
    'portrait'  : "screen and (orientation:portrait) "
);

@mixin for_breakpoint($breakpoints) {
    $conditions : ();
    @each $breakpoint in $breakpoints {
        $conditions: list.append(
            $conditions,
            #{map-get($media_queries, $breakpoint)},
            comma
        );
    }
    
    @media #{$conditions} {
        @content;
    }
}